import React from "react";
import { Routes, Route } from "react-router-dom";
import { useScrollTop } from "hooks";
import { Cookies, CookiesProvider } from "react-cookie";
import { Loader } from "Components";
import RouteChangeTracker from "Components/RouteChangeTracker";
import axios from "axios";
import "./App.css";

const Layout = React.lazy(
  () => import(/* webpackChunkName: "layout" */ "Components/Layout"),
);
const CompleteSignup = React.lazy(
  () =>
    import(/* webpackChunkName: "complete-signup" */ "Views/CompleteSignup"),
);
const NotFound = React.lazy(
  () => import(/* webpackChunkName: "notFound" */ "Views/Auth/NotFound"),
);
const Project = React.lazy(
  () => import(/* webpackChunkName: "project" */ "Views/Project"),
);
const Explorer = React.lazy(
  () => import(/* webpackChunkName: "explorer" */ "Views/Explorer"),
);
const Share = React.lazy(
  () => import(/* webpackChunkName: "share" */ "Views/Share"),
);
const Page500 = React.lazy(
  () => import(/* webpackChunkName: "page500" */ "Views/500"),
);
const Landing = React.lazy(
  () => import(/* webpackChunkName: "landingPage" */ "Views/Landing"),
);

function App() {
  React.useMemo(() => {
    const cookie = new Cookies();
    cookie.get("user_ip") ||
      axios
        .get("https://ipinfo.io/json?token=f569550f740f1c")
        .then((res) => {
          // console.log(res);
          cookie.set("user_ip", res.data.ip);
          cookie.set("user_country", res.data.country);
          cookie.set("user_city", res.data.city);
          cookie.set("user_loc", res.data.loc);
          cookie.set("user_org", res.data.org);
        })
        .catch((_err) => {});
  }, []);

  // React.useEffect(() => {
  //   axios.get("https://api.ip.pe.kr/json/").then((res) => {
  //     // const userData = email
  //     //   ? {
  //     //       ip_address: res.data.ip,
  //     //       email,
  //     //     }
  //     //   : {
  //     //       ip_address: res.data.ip,
  //     //     };
  //     // Sentry.setUser({ ...userData });
  //   });
  // }, [email]);

  React.useEffect(() => {
    const { IMP } = window;
    IMP?.init("imp13102778");
  }, []);

  useScrollTop();
  RouteChangeTracker();
  return (
    <CookiesProvider>
      <React.Suspense fallback={<Loader />}>
        <Layout>
          <Routes>
            <Route path="/" element={<Landing />} />
            {/* <Route path="intro" element={<Home />} /> */}
            <Route path="completeSignup/*" element={<CompleteSignup />} />
            <Route path="notFound" element={<NotFound />} />
            <Route path="project/*" element={<Project />} />
            <Route path="explorer" element={<Explorer />} />
            <Route path="share/*" element={<Share />} />
            <Route path="500" element={<Page500 />} />
          </Routes>
        </Layout>
      </React.Suspense>
    </CookiesProvider>
  );
}

export default App;
